<template>
  <div class="box-modal-wrapper box-calendar-modal box-calendar-modal-pt">
    <div class="container">
      <div class="box-modal-header with-bg">
        <div class="d-flex justify-content-between align-items-center">
          <button type="button" class="close-modal-btn" @click="closeCalendarPtModal">
            <closeIcon/>
          </button>
          <div class="box-modal-header-title">
            <p>TREINO COM PT</p>
          </div>
          <!--<button type="button" class="trash-modal-btn">
            <trashIcon/>
          </button>-->
        </div>
        <div class="box-modal-header-form">
          <div class="row align-items-center">
            <div class="col-12 col-lg-6 box-modal-header-form-column">
              <div class="form-field">
                <FormulateInput type="select" name="coach" label="Treinador" :options="{first: 'First', second: 'Second', third: 'Third', fourth: 'Fourth'}" />
              </div>
            </div>
            <div class="col-12 col-lg-6 box-modal-header-form-column">
              <div class="credits-wrapper d-flex justify-content-between align-items-center">
                <p class="credits-title"><span>0</span> Créditos Disponiveis</p>
                <button type="button" class="credits-btn">
                  <plus/>
                </button>
              </div>
              <div class="workout-day-wrapper d-flex justify-content-between align-items-center">
                <p class="workout-day-label">Custo</p>
                <p class="workout-day-value">10 Créditos</p>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="box-modal-body d-flex justify-content-between align-items-center flex-column">
        <div>
          <div class="workout-day-wrapper d-flex justify-content-between justify-content-lg-center align-items-center">
            <p class="workout-day-label">Dia</p>
            <p class="workout-day-value">Sex, 20/11/2020</p>
          </div>
          <div class="calendar-wrapper">
            <v-date-picker :attributes="calendarAttributes" class="big-calendar" value="" :min-date='new Date()' :available-dates='{ start: new Date(), end: null }'></v-date-picker>
          </div>
          <div class="calendar-modal-hours-wrapper">
            <div class="calendar-modal-hours-holder">
              <div class="workout-day-wrapper d-flex justify-content-between justify-content-lg-center align-items-center">
                <p class="workout-day-label">Hora</p>
                <p class="workout-day-value">19h00</p>
              </div>
              <div class="calendar-modal-hours d-flex justify-content-start align-items-start">
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="workout-pt-author-slider-item">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="author-slider-item-radio">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-modal-body-button-wrapper">
          <button type="button" class="generic-btn red-gradient big-text">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plus from "@/components/svg/plus.vue";
import closeIcon from "@/components/svg/close.vue";
import trashIcon from "@/components/svg/trash.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";

export default {
  name: "calendar-modal",
  components: {
    closeIcon,
    trashIcon,
    clock,
    level,
    fire,
    plus,
  },
  data: function () {
    return {
      calendarAttributes: [
        {
          dot: {
            style: {
              backgroundColor: '#a01e1d',
            }
          },
          dates: [
            new Date(2021, 0, 4), // Jan 4th
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 15), // Jan 15th
          ],
        },
        {
          dot: {
            style: {
              backgroundColor: '#777777',
            }
          },
          dates: [
            new Date(2021, 0, 4), // Jan 4th
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 15), // Jan 15th
          ],
        },
      ],
    }
  },
  methods: {
    closeCalendarPtModal: function () {
      document.getElementsByClassName("box-calendar-modal-pt")[0].classList.remove('open');
    }
  },
}


</script>