<template>
  <div class="dashboard calendar-list-page">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-6 calendar-list-column">
          <v-date-picker :attributes="calendarAttributes" @dayclick='dayClicked' class="big-calendar" v-if="!!calendarAttributes"></v-date-picker>
        </div>
        <div class="col-12 col-xl-6 calendar-list-column">
          <div class="workouts-section" v-if="workouts.length === 0">
            <p class="section-title">Treinos</p>
            <p>Selecione uma data com treino</p>
          </div>
          <div class="workouts-section" v-if="!!workouts && workouts != ''">
            <p class="section-title">Treinos</p>
            <div class="workouts-section-list">
              <div class="row">
                <div class="col-12 col-lg-6 workouts-section-list-column" v-for="(item,index) in workouts" :key="index">
                  <workoutItem :workoutItem="item" class="item-mobile-layout-2"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<calendarModalWorkout/>-->
    <calendarModalPt/>
    <successModal/>
  </div>
</template>
<script>
import pencil from "@/components/svg/pencil.vue";
import workoutItem from "@/components/workouts/workout-item.vue";
import calendarModalWorkout from "@/components/modals/calendar-modal-workout.vue";
import calendarModalPt from "@/components/modals/calendar-modal-pt.vue";
import successModal from "@/components/modals/success-modal.vue";
import axios from "axios";

export default {
  name: "calendar-list",
  components: {
    pencil,
    workoutItem,
    calendarModalWorkout,
    calendarModalPt,
    successModal,
  },
  data: function () {
    return {
      calendarInfo: [],
      calendarAttributes: [],
      workouts: [],
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.calendario,
    }
  },
  methods: {
    toggleCalendarWorkoutModal() {
      let element = document.getElementsByClassName("box-calendar-modal-workout")[0];

      if (element.classList.contains("open")) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    toggleCalendarPtModal() {
      let element = document.getElementsByClassName("box-calendar-modal-pt")[0];

      if (element.classList.contains("open")) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    dayClicked(day) {
      let selectedDate = day.id;

      if (selectedDate in this.calendarInfo) {
        for (let date in this.calendarInfo) {
          if (date == selectedDate) {
            this.workouts = this.calendarInfo[date];
          }
        }
      } else {
        this.workouts = '';
      }
    }
  },
  mounted() {
    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'calendar', {
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.calendarInfo = response.data.model;

          let calendarRedDates = [];

          for (let date in this.calendarInfo) {
            let calendarDate = new Date(date);

            calendarRedDates.push(calendarDate);
          }

          this.calendarAttributes = [
            {
              dot: {
                style: {
                  backgroundColor: '#a01e1d',
                }
              },
              dates: calendarRedDates,
            }
          ]
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {
    });
  },
  created() {
    this.$root.pageTitle = this.$root.translations.calendario;
  }
}
</script>