<template>
  <div class="box-modal-wrapper box-success-modal">
    <div class="container">
      <div class="box-modal-header">
        <div class="d-flex justify-content-center align-items-center">
          <div class="box-modal-header-title">
            <p>TREINO COM PT</p>
          </div>
        </div>
      </div>
      <div class="box-modal-body d-flex justify-content-between align-items-center flex-column">
        <div></div>
        <div class="sucess-modal-content-wrapper">
          <div class="sucess-modal-icon-wrapper d-flex justify-content-center align-items-center flex-column">
            <div class="sucess-modal-icon d-flex justify-content-center align-items-center">
              <check/>
            </div>
            <p class="sucess-modal-icon-title">Submetido com Sucesso!</p>
            <p class="sucess-modal-icon-subtitle">Aguarde confirmação via e-mail ou notificação da validação da data solicitada</p>
          </div>
        </div>
        <div class="box-modal-body-button-wrapper">
          <p class="sucess-modal-description d-lg-none">O treino só pode ser cancelado / alterado com 48h de antecedência. Caso não seja comunicado no tempo estipulado os créditos não serão retribuidos.</p>
          <button type="button" class="generic-btn red-gradient big-text">Voltar ao Ínicio</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import check from "@/components/svg/check.vue";

export default {
  name: "success-modal",
  components: {
    check
  }
}


</script>